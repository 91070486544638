<template>
  <div>
    <div
      class="flex justify-between items-center custom:py-0 custom:pb-2 xl:pb-6 xl:pt-4 w-full"
    >
      <h1 class="xl:text-2xl custom:text-xl text-left m-0">
        {{ dictionary.dataview_title }}
      </h1>
      <IconField class="w-1/2">
        <InputIcon class="pi pi-search custom:text-sm xl:text-base" />
        <InputText
          v-model="searchValue"
          type="text"
          :placeholder="dictionary.menu_search"
          class="w-full custom:p-1 xl:p-2 custom:text-sm xl:text-base mr-2 search-input"
        />
      </IconField>
    </div>
    <div
      v-if="filteredModules.length === 0"
      class="w-full flex justify-center items-center h-96 text-center py-4"
    >
      <div class="flex flex-row items-center justify-center w-full">
        <img
          src="../../../assets/no-file.png"
          alt="No file"
          class="no-options-image custom:w-10 xl:w-12 mr-4"
        />
        <h1 class="text-2xl">{{ dictionary.trainings_not_found }}</h1>
      </div>
    </div>
    <DataView
      :value="filteredModules"
      :rows="4"
      :paginator="true"
      class="relative"
    >
      <template #list="slotProps">
        <div class="flex flex-row flex-wrap">
          <div
            v-for="(item, index) in slotProps.items"
            :key="index"
            class="w-1/2 pr-6"
          >
            <div class="flex flex-row">
              <div class="w-2/5 flex items-center">
                <div v-if="!item.url" class="w-full">
                  <Skeleton />
                </div>
                <img
                  v-else
                  :src="item.url"
                  :alt="item.short"
                  class="w-full border-round"
                  loading="lazy"
                />
              </div>
              <div
                class="desc flex flex-col justify-between items-start w-3/5 pl-4"
              >
                <div class="w-full flex flex-row justify-between items-center">
                  <div class="flex flex-row items-center">
                    <span
                      :class="
                        item.department.toLowerCase() +
                        '-bg p-1 rounded-lg mr-4 custom:text-xs xl:text-base'
                      "
                    >
                      {{ item.department }}
                    </span>
                    <span
                      :class="
                        item.department.toLowerCase() +
                        '-txt custom:text-base xl:text-xl'
                      "
                    >
                      {{ item.short }}
                    </span>
                  </div>
                  <span class="font-medium text-sm custom:text-xs xl:text-base">
                    {{ dictionary.dataview_item2 + item.duration }} min
                  </span>
                </div>
                <div>
                  <span class="w-full custom:text-xs xl:text-sm">{{
                    dictionary.dataview_item3
                  }}</span>
                </div>
                <span
                  class="objective w-full text-left custom:text-xs xl:text-sm mb-2"
                >
                  <ul class="flex flex-row">
                    <li
                      v-for="(tag, index) in getFirstTwoTags(item.tag)"
                      :key="index"
                      class="mr-2"
                    >
                      #{{ tag }}
                    </li>
                  </ul>
                </span>
                <div class="flex flex-row w-full mt-2">
                  <router-link
                    :to="{ name: 'module' }"
                    @click="handleDisplayModule(item)"
                    class="dataview-btn mr-2 custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:text-base xl:h-auto xl:mt-0 rounded flex-grow"
                  >
                    <i class="pi pi-map mr-4"></i>
                    <p>{{ dictionary.dataview_show }}</p>
                  </router-link>
                  <router-link
                    :to="{ name: 'module-comparator' }"
                    @click="handleDisplayModule(item)"
                    class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:text-base xl:h-auto xl:mt-0 rounded flex-grow"
                  >
                    <i class="pi pi-arrow-right-arrow-left mr-4"></i>
                    <p>{{ dictionary.dataview_compare }}</p>
                  </router-link>
                  <transition name="fade" mode="out-in">
                    <Button
                      v-if="isAdmin"
                      :label="dictionary.dataview_edit"
                      @click="handleOpenEditSlotComponent(item)"
                      icon="pi pi-pencil"
                      class="add-module-btn ml-2 custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:ml-2 xl:text-base xl:h-auto xl:mt-0 rounded flex-grow"
                    />
                  </transition>
                </div>
              </div>
            </div>
            <Divider />
          </div>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, computed, onMounted } from "vue";
import { useStore } from "../../../../store/store.js";
import DataView from "primevue/dataview";
import Divider from "primevue/divider";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import { dictionary } from "@/dictionary";
const props = defineProps({
  data: Array,
});
const modules = ref(props.data);
const searchValue = ref("");
const store = useStore();
const isAdmin = computed(() => store.admin);

const filteredModules = computed(() => {
  const searchValueLower = searchValue.value.toLowerCase();
  const startsWithSearchValue = modules.value.filter((mod) => {
    return mod.short.toLowerCase().startsWith(searchValueLower);
  });

  const includesSearchValue = modules.value.filter((mod) => {
    return (
      !mod.short.toLowerCase().startsWith(searchValueLower) &&
      mod.short.toLowerCase().includes(searchValueLower)
    );
  });

  return [
    ...startsWithSearchValue.sort((a, b) => a.short.localeCompare(b.short)),
    ...includesSearchValue.sort((a, b) => a.short.localeCompare(b.short)),
  ];
});

const getFirstTwoTags = (tags) => {
  return tags.slice(0, 2);
};

watch(
  () => props.data,
  (newData) => {
    modules.value = newData;
  },
  { immediate: true }
);

const handleDisplayModule = (mod) => {
  store.setSelectedModule(mod);
};

const handleOpenEditSlotComponent = (item) => {
  store.setSelectedModule(item);
  store.$patch({ showEditSlotDialog: true });
};

onMounted(async () => {
  await store.fetchData();
});
</script>
<style scoped>
.desc {
  min-height: 124px;
  max-height: 163px;
}
.dataview-btn {
  transition: ease-in;
  transition-duration: 0.2s;
}
.dataview-btn:hover {
  color: #fbbf24;
}
.search-input::placeholder {
  text-transform: none;
}
.search-input {
  text-transform: uppercase;
  border: 1px solid #c4e9ff;
  color: #c4e9ff;
}

</style>
