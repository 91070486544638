<template>
  <div class="w-full h-full flex flex-col items-center px-4 pt-37 m-0 relative">
    <div class="sidebar-title flex items-center justify-left w-full absolute top-14">
      <span class="ml-4 font-semibold text-2xl text-primary">{{
        dictionary.sidebar_title
      }}</span>
    </div>
    <p class="w-full">{{ dictionary.sidebar_item3 }}</p>
    <Divider class="m-2" />
    <div class="w-full xl:py-4 custom:py-0">
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedDepartment"
          inputId="dd-departments"
          :options="departments"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-departments">{{ dictionary.filter_label0 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedType"
          inputId="dd-type"
          :options="types"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-type">{{ dictionary.filter_label1 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedTarget"
          inputId="dd-target"
          :options="targets"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-target">{{ dictionary.filter_label5 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedDuration"
          inputId="dd-target"
          :options="durations"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
        />
        <label for="dd-target">{{ dictionary.filter_label11 }}</label>
      </FloatLabel>
      <FloatLabel class="w-full mb-3 custom:text-xs xl:text-base">
        <Dropdown
          v-model="selectedTags"
          inputId="dd-tags"
          :options="uniqueTags"
          optionLabel="name"
          class="w-full custom:text-xs xl:text-base"
          filter
        />
        <label for="dd-tags">{{ dictionary.filter_label6 }}</label>
      </FloatLabel>
    </div>
    <Divider class="m-2" />
    <div class="w-full py-4">
      <Button
        :label="dictionary.filter_btn_clear"
        icon="pi pi-refresh"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:text-base xl:h-auto xl:mt-0 rounded w-full"
        @click="clearFilters()"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, watch } from "vue";
import { useStore } from "../../../store/store.js";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Divider from "primevue/divider";
import FloatLabel from "primevue/floatlabel";
import { dictionary } from "../../dictionary.js";
const store = useStore();
const departments = store.departments;
const types = store.types;
const targets = store.targets;
const durations = store.durations;

const props = defineProps({
  toggleFilter: Function,
  data: Array,
});

const selectedType = computed({
  get: () => store.selectedType,
  set: (value) => (store.selectedType = value),
});
const selectedTarget = computed({
  get: () => store.selectedTarget,
  set: (value) => (store.selectedTarget = value),
});
const selectedTags = computed({
  get: () => store.selectedTags,
  set: (value) => (store.selectedTags = value),
});
const selectedDepartment = computed({
  get: () => store.selectedDepartment,
  set: (value) => (store.selectedDepartment = value),
});
const selectedDuration = computed({
  get: () => store.selectedDuration,
  set: (value) => (store.selectedDuration = value),
});

const uniqueTags = computed(() => {
  const tags = [...new Set(props.data.flatMap((item) => item.tag))]
    .sort()
    .map((tag) => ({ name: tag }));
  return tags;
});

watch(
  [
    selectedType,
    selectedTarget,
    selectedTags,
    selectedDepartment,
    selectedDuration,
  ],
  () => {
    store.setFilters({
      type: selectedType.value,
      target: selectedTarget.value,
      tags: selectedTags.value,
      department: selectedDepartment.value,
      duration: selectedDuration.value,
    });
  }
);

const clearFilters = () => {
  selectedType.value = null;
  selectedTarget.value = null;
  selectedTags.value = null;
  selectedDepartment.value = null;
  selectedDuration.value = null;
  store.setFilterActive(true);
};
</script>

<style scoped>
.btn-filter {
  background: transparent !important;
  width: 100%;
  color: #c4e9ff;
  transition: ease-in-out;
  transition-duration: 0.2s;
  text-align: left;
}
.btn-filter:hover {
  color: rgba(254, 196, 30, 1);
  background-color: transparent;
}
.p-inputtext {
  padding: 0.75rem 0.75rem;
  width: 100%;
}
</style>
