<template>
  <div v-if="store.token && data.length" class="content">
    <ContentDisplay :data="data" />
  </div>
  <div v-if="!store.token && !data.length" class="flex justify-center">
    <LoginComponent />
  </div>
  <img class="mapbg" src="./assets/mapbcg.png" alt="background" />
</template>

<script setup>
import { watchEffect, watch, ref } from "vue";
import { useStore } from "../store/store.js";
import ContentDisplay from "./components/ContentDisplay.vue";
import LoginComponent from "./components/login/LoginComponent.vue";
import "../style/font.css";

const store = useStore();
const data = ref([]);

watchEffect(() => {
  data.value = store.data;
});

watch(
  () => store.data,
  (newValue) => {
    data.value = newValue;
  }
);

watch(
  () => store.token,
  (newToken) => {
    if (newToken) {
      store.fetchData();
    }
  },
  { immediate: true }
);
</script>

<style>
#app {
  font-family: lidlFontProReg, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: relative;
  color: #c4e9ff !important;
  width: 100%;
  height: 100%;
  margin: 0;
}
.mapbg {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}
</style>
