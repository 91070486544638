<template>
  <div class="card flex justify-content-center">
    <Sidebar
      v-model:visible="visible"
      :modal="false"
      class="dark:border-0 sidebar custom:w-1/5 xl:w-1/6"
      :baseZIndex="1000"
      :dismissable="false"
    >
      <div class="w-full h-sidebar-content grid grid-cols-5 pl-4 pb-2">
        <div
          class="w-full h-full pt-8"
          :class="!showFilter ? 'col-span-4' : 'col-span-1'"
        >
          <div class="sidebar-title flex items-center">
            <img :src="logo" alt="logo" class="custom:w-10 xl:w-12" />
            <span class="ml-3 font-semibold text-2xl text-primary">{{
              !showFilter ? dictionary.sidebar_title : ""
            }}</span>
          </div>
          <div
            class="flex flex-col justify-between w-full h-sidebar-content pt-8"
          >
            <ul class="list-none pr-4 overflow-hidden h-full">
              <!-- <li :class="!showFilter ? 'custom:h-12 xl:h-16' : 'btn-small'">
                <router-link
                  to="/dashboard"
                  class="flex items-center cursor-pointer px-4"
                  @click="() => {store.setFilterActive(false), showFilter = false}"
                >
                  <i class="pi pi-home mr-2"></i>
                  <span class="font-medium custom:text-sm xl:text-base">{{
                    !showFilter ? dictionary.sidebar_item1 : ""
                  }}</span>
                </router-link>
              </li> -->
              <li :class="!showFilter ? 'custom:h-12 xl:h-16' : 'btn-small'">
                <router-link
                  to="/module-library"
                  class="flex items-center cursor-pointer px-4"
                  :title="dictionary.sidebar_lib"
                >
                  <i class="pi pi-book mr-2"></i>
                  <span class="font-medium custom:text-sm xl:text-base">{{
                    !showFilter ? dictionary.sidebar_item2 : ""
                  }}</span>
                </router-link>
              </li>
              <li
                :class="
                  !showFilter ? 'custom:h-12 xl:h-16 li-btn' : 'btn-small'
                "
              >
                <Button
                  :title="dictionary.sidebar_doc"
                  :label="!showFilter ? dictionary.sidebar_item9 : ''"
                  icon="pi pi-link"
                  @click="goToDocumentation"
                />
              </li>
              <li
                :class="
                  !showFilter ? 'custom:h-12 xl:h-16 li-btn' : 'btn-small'
                "
              >
                <Button
                  :title="dictionary.sidebar_bug"
                  :label="!showFilter ? dictionary.sidebar_item10 : ''"
                  icon="bi bi-bug"
                  @click="reportBug"
                />
              </li>
            </ul>
          </div>
        </div>
        <div
          class="filter-wrapper h-full w-full"
          :class="!showFilter ? 'col-span-1' : 'col-span-4'"
        >
          <!-- <Button
            v-if="!showFilter"
            :label="dictionary.sidebar_item3"
            icon="pi pi-angle-right"
            class="filter-button w-full h-full custom:text-sm xl:text-base"
            @click="toggleFilter"
          /> -->
          <ModuleFilter
            v-if="showFilter"
            :toggleFilter="toggleFilter"
            :data="props.data"
          />
        </div>
      </div>
      <div
        class="absolute custom:bottom-2 xl:bottom-5 left-0 w-full h-copyrights px-4 flex flex-col justify-end custom:text-xs xl:text-sm"
      >
        <Divider />
        <div class="flex flex-row justify-between w-full">
          <small>{{ dictionary.sidebar_copy }}</small>
          <small>{{ version }}</small>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, onUnmounted } from "vue";
import { useStore } from "../../../store/store.js";
import { useRouter } from "vue-router";
import Sidebar from "primevue/sidebar";
import Divider from "primevue/divider";
import Button from "primevue/button";
import pkg from "../../../package.json";
import { dictionary } from "../../dictionary.js";
import logo from "../../assets/logo.png";
import ModuleFilter from "../filter/ModuleFilter.vue";
const store = useStore();
const router = useRouter();
const props = defineProps({
  data: Array,
});
const version = pkg.version;
let visible = ref(true);
let showFilter = ref(true);

const toggleFilter = () => {
  showFilter.value = !showFilter.value;
  store.setFilterActive(showFilter.value);
  if (showFilter.value) {
    router.push({ name: "module-library" });
  } else {
    router.push({ name: "module-library" });
  }
};

const reportBug = () => {
  const jiraUrl =
    "https://jira.schwarz/secure/CreateSubTaskIssue.jspa?parentIssueId=3196131&pid=18200&issuetype=Sub-task";
  window.open(jiraUrl, "_blank");
};

const goToDocumentation = () => {
  const jiraUrl = "https://confluence.schwarz/display/ELD/Technologie";
  window.open(jiraUrl, "_blank");
};

const handleKeydown = (event) => {
  if (event.key === "Escape") {
    event.stopPropagation();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeydown, true);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown, true);
});
</script>

<style scoped>
.sidebar-title {
  height: 10%;
}
.h-sidebar-content {
  height: 93%;
}
.h-copyrights {
  height: 7%;
}
.card {
  width: 20%;
  font-family: lidlFontProReg, Helvetica, Arial, sans-serif !important;
}

ul {
  position: relative;
}

li {
  width: 100%;
  border: 1px solid rgba(196, 233, 255, 0.3);
  border-radius: 10px;
  display: flex;
  margin-bottom: 1rem;
  transition: ease-in;
  transition-duration: 0.2s;
}
li a {
  width: 100%;
  height: 100%;
}
li:hover {
  background-color: #353e57;
}
.filter-wrapper {
  background-color: #18212d;
  border-bottom-left-radius: 10px;
}

.btn-small {
  width: 40px;
  height: 40px;
}

.btn-small a {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-small a i {
  margin: 0;
}

.li-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.li-btn button:hover {
  background-color: #353e57;
}
</style>
