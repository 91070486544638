<template>
  <Menubar class="custom:ml-72 xl:ml-96">
    <template #start>
      <div class="h-full flex items-center justify-between">
        <div class="flex flex-row">
          <Button
            icon="pi pi-arrow-circle-left"
            :title="dictionary.nav_back"
            @click="navGoBack"
          />
          <h1 class="route-title text-2xl text-left">{{ currentRouteName }}</h1>
        </div>
        <div class="flex items-center">
          <transition name="fade" mode="out-in">
            <Button
              v-if="!isAdmin"
              icon="pi pi-cog"
              :title="dictionary.admin_panel"
              @click="openAdminDialog"
              class="custom:text-xs xl:text-base"
            />
            <div v-else class="flex items-center">
              <Button
                icon="pi pi-plus-circle"
                :label="dictionary.admin_add_module"
                @click="handleOpenEditSlotComponent"
                class="add-module-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-2 xl:text-base xl:h-auto xl:mt-0 rounded"
              />
              <Button
                icon="pi pi-times-circle"
                @click="handleCloseAdmin"
                :title="dictionary.admin_close"
                class="close-admin-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 xl:p-0 xl:text-base xl:h-auto xl:mt-0 xl:ml-2 rounded"
              />
            </div>
          </transition>
          <Button
            icon="pi pi-sign-out"
            @click="handleLogout"
            :title="dictionary.logout"
            class="custom:text-xs xl:text-base"
          />
        </div>
      </div>
      <Divider />
    </template>
  </Menubar>
  <AdminComponent ref="adminComponent" />
  <Toast ref="toast" />
</template>

<script setup>
import { ref, watch, computed } from "vue";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "../../../store/store.js";
import Divider from "primevue/divider";
import Toast from "primevue/toast";
import AdminComponent from "../admin/AdminComponent.vue";
import { dictionary } from "../../dictionary.js";

const store = useStore();
const loading = ref(false);
const toast = ref(null);
const route = useRoute();
const router = useRouter();
const isAdmin = computed(() => store.admin);
const currentRouteName = ref(route.meta.routeName);
const adminComponent = ref(null);

watch(
  () => route.path,
  () => {
    currentRouteName.value = route.meta.routeName;
  }
);

const openAdminDialog = () => {
  if (currentRouteName.value !== "module-library") {
    router
      .push({ name: "module-library" })
      .then(() => {
        localStorage.removeItem("mod");
        adminComponent.value.openDialog();
      })
      .catch((error) => {
        console.error("Navigation error:", error);
      });
  } else {
    adminComponent.value.openDialog();
  }
};

const handleOpenEditSlotComponent = () => {
  localStorage.removeItem("mod");
  store.$patch({ showEditSlotDialog: true });
};

const handleCloseAdmin = () => {
  store.$patch({ admin: false });
};

const handleLogout = async () => {
  loading.value = true;
  try {
    const response = await fetch("https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/admin/logout", {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setToast();
      const timer = setTimeout(() => {
        store.clearToken();
        store.$reset();
        loading.value = false;
        window.location.reload();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      const message = await response.text();
      console.error(message);
      loading.value = false;
    }
  } catch (error) {
    console.error(error);
  }
};

const setToast = () => {
  toast.value.add({
    severity: "success",
    summary: "Success",
    detail: dictionary.logout_info,
    life: 2000,
  });
};
const navGoBack = () => {
  router.back();
};
</script>

<style scoped>
.p-menubar {
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  max-height: 60px;
}
</style>
