<template>
  <div class="training-wrapper p-4" v-if="mod1">
    <div class="row">
      <div class="w-1/5">
        <img
          :src="mod1.url"
          :alt="mod1?.short"
          class="w-full border-round custom:mt-6 xl:mt-0 mb-4"
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <span
          :class="[
            mod1.department.toLowerCase() + '-txt custom:text-base xl:text-xl',
            mod1 && mod2 && isCommon(mod1.short, mod2.short)
              ? 'common-element'
              : '',
          ]"
        >
          {{ mod1.short }}
        </span>
        <div>
          <span class="custom:text-xs xl:text-sm">{{
            dictionary.dataview_item3
          }}</span>
        </div>
        <span class="text-left custom:text-xs xl:text-sm">{{
          mod1.objective
        }}</span>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4 mt-4">
      <div
        :class="[
          'mod-property col-span-2 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.department, mod2.department)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_department }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.department
        }}</span>
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.duration, mod2.duration)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_duration }}</small>
        <span class="text-left custom:text-sm xl:text-base"
          >{{ mod1.duration }}{{ dictionary.comparator_min }}</span
        >
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.type, mod2.type)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_type }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.type
        }}</span>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4 mt-4">
      <div
        :class="[
          'mod-property col-span-2 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.element, mod2.element)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_element }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.element
        }}</span>
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.target, mod2.target)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_target }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.target
        }}</span>
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.employee, mod2.employee)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_emplyee }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.employee
        }}</span>
      </div>
    </div>
    <Divider />
    <ul class="w-full">
      <li
        v-for="(topic, index) in mod1.topic"
        :key="index"
        class="w-full mb-4 p-2 mt-4 li-topic"
      >
        <div class="w-full flex justify-start mb-2">
          <span class="text-left custom:text-sm xl:text-base">{{
            dictionary.comparator_topics
          }}</span>
        </div>
        <div class="grid grid-cols-4 gap-4 w-full">
          <span
            :class="[
              'mod-property p-2 text-left flex items-center custom:text-sm xl:text-base col-span-1',
              isCommonElement(topic.tag[0]) || isCommonElement(topic.tag[1])
                ? 'common-element'
                : '',
            ]"
          >
            {{ topic.name }}
          </span>
          <div
            :class="[
              'mod-property p-2 text-left flex flex-col justify-center custom:text-sm xl:text-base col-span-1',
            ]"
          >
            <small class="text-left">{{
              dictionary.comparator_duration
            }}</small>
            <span>{{ topic.time }}{{ dictionary.comparator_min }}</span>
          </div>
          <div
            :class="[
              'mod-property p-2 text-left flex flex-col justify-center custom:text-sm xl:text-base col-span-1',
              isCommonElement(topic.tag[0]) ? 'common-element' : '',
            ]"
          >
            <small class="text-left">{{ dictionary.comparator_ttag }}</small>
            <span>{{ topic.tag[0] }}</span>
          </div>
          <div
            v-if="topic.tag[1]"
            :class="[
              'mod-property p-2 text-left flex flex-col justify-center custom:text-sm xl:text-base col-span-1',
              mod1 && isCommonElement(topic.tag[1]) ? 'common-element' : '',
            ]"
          >
            <small class="text-left">{{ dictionary.comparator_ttag }}</small>
            <span>{{ topic.tag[1] }}</span>
          </div>
        </div>
        <ul class="w-full">
          <li
            v-for="(stopic, index) in topic.stopic"
            :key="index"
            class="w-full mb-4"
          >
            <div class="w-full flex justify-start p-2">
              <span class="custom:text-sm xl:text-base">{{
                dictionary.comparator_stopics
              }}</span>
            </div>
            <div class="grid grid-cols-4 gap-4 w-full">
              <span
                :class="[
                  'mod-property p-2 text-left flex items-center custom:text-sm xl:text-base col-span-1',
                  isCommonElement(stopic.tag[0]) ? 'common-element' : '',
                ]"
                >{{ stopic.name }}</span
              >
              <div
                :class="[
                  'mod-property p-2 text-left flex flex-col justify-start custom:text-sm xl:text-base col-span-1',
                ]"
              >
                <small class="text-left">{{
                  dictionary.comparator_duration
                }}</small>
                <span>{{ stopic.time }}{{ dictionary.comparator_min }}</span>
              </div>
              <div
                :class="[
                  'mod-property p-2 text-left flex flex-col justify-start custom:text-sm xl:text-base col-span-1',
                  'skill-' + stopic.skill.toLowerCase(),
                ]"
              >
                <small class="text-left">{{
                  dictionary.comparator_skill
                }}</small>
                <span>{{ stopic.skill }}</span>
              </div>
              <div
                :class="[
                  'mod-property p-2 text-left flex flex-col justify-start custom:text-sm xl:text-base col-span-1',
                  isCommonElement(stopic.tag[0]) ? 'common-element' : '',
                ]"
              >
                <small class="text-left">{{
                  dictionary.comparator_stag
                }}</small>
                <span>{{ stopic.tag[0] }}</span>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <Divider />
    </ul>
    <div class="flex flex-col justify-start mt-4">
      <span class="text-left mb-2 custom:text-sm xl:text-base">{{
        dictionary.comparator_tags
      }}</span>
      <ul class="grid grid-cols-3 gap-4 w-full">
        <li
          v-for="(tag, index) in filteredTags"
          :key="index"
          :class="[
            'mod-property p-2 text-left flex items-center custom:text-sm xl:text-base col-span-1',
            mod1 && mod2 && findCommonValueInArray(tag, mod2Tags)
              ? 'common-element'
              : '',
          ]"
        >
          <span>{{ tag }}</span>
        </li>
      </ul>
    </div>
    <Divider />
    <div class="w-full flex flex-row">
      <router-link
        :to="{ name: 'module' }"
        @click="handleDisplayModule(mod1)"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 custom:mr-3 xl:p-2 xl:mr-4 xl:text-base xl:h-auto xl:mt-0 rounded"
      >
        <i class="pi pi-map mr-2"></i>
        {{ dictionary.dataview_show }}
      </router-link>
      <Button
        icon="pi pi-times"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 custom:mr-3 xl:p-2 xl:mr-4 xl:text-base xl:h-auto xl:mt-0 rounded"
        :label="dictionary.comparator_remove_btn"
        @click="removeModule"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, computed } from "vue";
import { useStore } from "../../../../store/store.js";
import { dictionary } from "../../../dictionary.js";
import Divider from "primevue/divider";
import Button from "primevue/button";

const store = useStore();
const props = defineProps({
  mod1: Object,
  mod2: Object,
  slot1: Boolean,
});
const mod1 = ref(props.mod1);
const mod2 = ref(props.mod2);

const filteredTags = computed(() => {
  return mod1.value.tag.filter((tag) => tag !== "");
});

const mod2Tags = computed(() => {
  return mod2.value.tag.filter((tag) => tag !== "");
});

const emit = defineEmits(["update:mod1", "update:slot1"]);

const handleDisplayModule = (mod) => {
  store.setSelectedModule(mod);
};

const removeModule = () => {
  emit("update:mod1", null);
  emit("update:slot1", true);
  localStorage.removeItem("mod");
};

const isCommon = (value1, value2) => {
  return value1 === value2;
};

const findCommonValueInArray = (val, arr) => {
  return arr.includes(val);
};

const isCommonElement = (tag) => {
  if (!mod2.value) return false;
  const mod2Tags = mod2.value.topic
    .flatMap((t) => [...t.tag, ...(t.stopic?.flatMap((s) => s.tag) || [])])
    .filter((tag) => tag !== undefined);
  return findCommonValueInArray(tag, mod2Tags);
};

watch(
  () => props.mod2,
  (newMod2) => {
    mod2.value = newMod2;
  }
);
</script>

<style scoped>
.training-wrapper {
  width: 100%;
  height: auto;
}
.row {
  width: 100%;
}
.mod-property {
  background-color: #1f2937;
}

.stopic-property {
  flex: 1 1 auto;
}

.common-element {
  background-color: #b4e68c;
  color: #1f2937;
}
.tag {
  border: 1px solid #c4e9ff;
}

.grid {
  display: grid;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gap-4 {
  gap: 1rem;
}
.col-span-1 {
  grid-column: span 1;
}

.col-span-2 {
  grid-column: span 2;
}

.li-topic {
  border: 1px solid #c4e9ff;
}
</style>